// the default LaTeX link colors are... garish.
a, a:visited {
  color: #2e74b5;
}

// left-justified text is more readable than fully justified
p {
  text-align: left;
}

.author {
  font-size: 80%;
}

header.site-header {
  margin-bottom: 2rem;
  width: 100%;
}

footer.site-footer {
  font-size: 80%;
  margin-top: 2rem;
  width: 100%;

  ul {
    display: inline-block;
    float: right;
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  .icon {
    svg {
      height: 1em;
      position: relative;
      top: 2px;
      width: auto;
    }
  }
}

div.photoset {
  display: flex;
  flex-flow : row wrap;
  justify-content: space-between;

  > figure {
    cursor: pointer;
    margin: 0.25rem;
    flex: 1;
    min-width: 20%;
    max-height: 8rem;

    figcaption {
      display: none;
    }

    img, video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

dialog.photo-modal {
  border: 0;
  padding: 2rem;
}
