body.page--index {
  max-width: 100ch;

  img.headshot {
    position: relative;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }

  h1 {
    text-align: left;
    margin-bottom: 0.5rem;
  }

  .index__links {
    margin-top: 1rem;
  }

  .icon {
    svg {
      max-width: 1.25em;
      height: auto;
      vertical-align: middle;
    }
  }

}
